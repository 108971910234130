/* Customize your main colors in :root variables */

@import '~antd/dist/antd.dark.css';

:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #000000;
  --title-text-color: #ff8e00;
}

body {
  background-color: var(--main-background-color);
  /*background: url('img/background.png');
  background-size: cover; */

  margin: 0;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #90b2ff;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

h1,
h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

/* Candy Shop styling overrides */
.css-1wewypr {
  color: black;
}

.css-1ago99h {
  color: black;
}

.candy-notification {
  display: none;
}
